<template>
  <div>
    <div class="tech-req__title">
      {{ text.menu_relative_shape[language] }}
    </div>
    <Button
      @click="setShowFilters"
      :text="!showFilters ? text.sp_show_filter[language] : text.sp_hide_filter[language]"
      type="attention"
      style="margin-bottom: 20px"
      block
    />
    <div class="tech-req__block_wrapper" v-if="showFilters">
      <div class="tech-req__block_container">
        <div>
          <div v-if="showShapeFilters.length > 0">
            <div class="tech-req__block_subtitle" style="margin-bottom: 20px">
              {{ text.sp_shape_acc[language] }}
            </div>
            <div class="block block__between block__align-center" style="margin-bottom: 20px">
              <div v-for="(item, index) in showShapeFilters" v-bind:key="`shape_f_${index}`">
                <AccuracyType
                  @click="setActiveFilter($event)"
                  :type="item"
                  :active="filters[item]"
                  v-if="item !== 'empty'"
                />
                <div v-else style="width: 50px; min-width: 50px; height: 35px"></div>
              </div>
            </div>
          </div>
          <Button
            @click="setActiveFilter('all')"
            block
            :type="filters.all ? 'attention' : 'default'"
            :text="
              filters.all ? text.sp_filter_hide_all[language] : text.sp_filter_show_all[language]
            "
          />
        </div>
      </div>
    </div>
    <div class="tech-req__block_wrapper" v-if="showEdit">
      <div class="tech-req__block_container">
        <div class="tech-req__block_title" style="margin-bottom: 20px">
          {{ text.sp_add[language] }}
        </div>
        <div>
          <div class="tech-req__block_subtitle" style="margin-bottom: 20px">
            {{ text.sp_shape_acc[language] }}
          </div>
          <div class="block block__between block__align-center" style="margin-bottom: 20px">
            <div v-for="(item, index) in typesShape" v-bind:key="`add_shape_${index}`">
              <AccuracyType @click="addTolerance(item)" :type="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(item, index) in tolerances" v-bind:key="`tolerances_${item.id}`">
      <div class="tech-req__block_wrapper" v-if="filters[item.type]">
        <div class="tech-req__block_container">
          <div class="block block__between block__align-center" style="margin-bottom: 20px">
            <div class="block__flex block__align-center">
              <AccuracyType :type="item.type" />
              <div style="margin-left: 10px; margin-right: 10px" class="tech-req__base_name">
                {{ text[`sp_${item.type}`][language] }}
              </div>
            </div>
            <Input
              v-if="item.edit"
              :max-width="60"
              :step="0.001"
              :min="0"
              :default-value="item.value ? item.value : '0'"
              type="number"
              @change="saveValue(index, $event)"
              :margin-bottom="0"
            />
            <div v-if="!item.edit" class="tech-req__block_show-value">
              {{ item.value ? item.value : "0" }}
            </div>
          </div>
          <div style="margin-bottom: 20px">
            <div style="margin-bottom: 20px">
              <div class="block__flex block__align-center" style="margin-bottom: 10px">
                <div class="tech-req__block_subtitle">
                  {{ text.sp_choose_surface_title[language] }}:
                </div>
                <div class="component__margin-left tech-req__block_value">
                  {{ +!!item.surface1 }}
                </div>
              </div>
              <div v-if="item.surface1" style="margin-bottom: 10px">
                <div
                  @mouseenter="setHoveredItem('tol_surface')"
                  @mouseleave="setHoveredItem(null)"
                  @click="showSurface(index, 'surface1')"
                  :class="`tech-req__base_button ${setItemStyle(1, 'surface', item)}`"
                >
                  <div>{{ setItemText(1, "surface", item) }}</div>
                  <CloseIcon
                    @click="deleteItem(index, 'surface1')"
                    v-if="hoveredItem === 'tol_surface' && item.edit"
                    class="tech-req__base_button_cancel"
                  />
                </div>
              </div>
              <div
                v-if="item.count < 1 && item.edit"
                @click="addItem('surface', index)"
                :style="`${
                  item.status === 'choose' ? 'background-color: rgba(0,0,0, .1);' : ''
                } margin-top: 10px;`"
                class="tech-req__block_add"
              >
                <AddLogo />
              </div>
            </div>
            <div style="margin-bottom: 20px" v-if="item.type === 'straightness'">
              <div class="block__flex block__align-center" style="margin-bottom: 10px">
                <div class="tech-req__block_subtitle">
                  {{ text.sp_choose_axis_title[language] }}:
                </div>
                <div class="component__margin-left tech-req__block_value">
                  {{ +!!item.axis1 }}
                </div>
              </div>
              <div v-if="item.axis1" style="margin-bottom: 10px">
                <div
                  @mouseenter="setHoveredItem('tol_axis')"
                  @mouseleave="setHoveredItem(null)"
                  @click="showSurface(index, 'axis1')"
                  :class="`tech-req__base_button ${setItemStyle(1, 'axis', item)}`"
                >
                  <div>{{ setItemText(1, "axis", item) }}</div>
                  <CloseIcon
                    @click="deleteItem(index, 'axis1')"
                    v-if="hoveredItem === 'tol_axis' && item.edit"
                    class="tech-req__base_button_cancel"
                  />
                </div>
              </div>
              <div
                v-if="item.count < 1 && item.edit"
                @click="addItem('axis', index)"
                :style="`${
                  item.status === 'choose' ? 'background-color: rgba(0,0,0, .1);' : ''
                } margin-top: 10px;`"
                class="tech-req__block_add"
              >
                <AddLogo />
              </div>
            </div>
          </div>
          <div class="block" v-if="showEdit">
            <Button
              @click="saveOrEdit(index)"
              block
              :type="item.edit ? 'primary' : 'attention'"
              :text="item.edit ? text.r_save[language] : text.sp_edit[language]"
            />
            <div
              class="tech-req__block_delete"
              @click="deleteTolerance(index)"
              style="margin-left: 10px"
            >
              <TrashIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import text from "@/assets/text/tech.json";
import AddLogo from "@/assets/images/tech/add.svg";
import CloseIcon from "@/assets/images/tech/close.svg";
import TrashIcon from "@/assets/images/tech/trash.svg";
import Button from "@/components/button/Button.vue";
import Input from "@/components/input/Input.vue";
import { query } from "@/utilities/axios";
import AccuracyType from "./AccuracyType.vue";

export default {
  name: "AccuracyOfShapeAndPosition",
  data() {
    return {
      text,
      showEdit: true,
      activeClicked: false,
      hoveredItem: null,
      activeIndex: null,
      activeToleranceType: null,
      clickedType: null,
      clickColor: { r: 0.674, g: 0.074, b: 0.145 },
      activeColor: { r: 0.674, g: 0.674, b: 0.145 },
      showShapeFilters: [],
      paramsLang: "ru",
      typesShape: ["straightness", "round", "surface", "cylinder", "profile"],
      filters: {
        straightness: true,
        round: true,
        surface: true,
        cylinder: true,
        profile: true,
        all: true,
      },
      tolerances: [],
      showFilters: false,
    };
  },
  props: {
    partData: {
      type: Object,
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    edit: function (val) {
      this.showEdit = val;
    },
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    checkFilters() {
      const shapeFilters = [];
      for (const item of this.typesShape) {
        const index = this.tolerances.findIndex((el) => el.type === item);
        if (index >= 0) {
          shapeFilters.push(item);
        }
      }
      if (shapeFilters.length > 0 && shapeFilters.length < 5) {
        [...Array(5 - shapeFilters.length).keys()].forEach(() => {
          shapeFilters.push("empty");
        });
      }
      this.showShapeFilters = shapeFilters;
    },
    setActiveFilter(type) {
      if (type !== "all") {
        this.filters[type] = !this.filters[type];
        let allResult = true;
        for (const item of Object.keys(this.filters).filter((el) => el !== "all")) {
          if (!this.filters[item]) {
            allResult = false;
            break;
          }
        }
        this.filters.all = allResult;
      } else {
        const value = !this.filters.all;
        this.filters.all = value;
        for (const item of Object.keys(this.filters)) {
          this.filters[item] = value;
        }
      }
    },
    setItemText(index, type, surface) {
      let result = "";
      if (type === "axis") {
        if (index === 1 && surface.axis1 === "choose") {
          result = this.text.sp_choose_base_surface[this.language];
        } else {
          result = this.text.sp_show_base_surface[this.language];
        }
      } else if (type === "surface") {
        if (index === 1 && surface.surface1 === "choose") {
          result = this.text.sp_choose_base_surface[this.language];
        } else {
          result = this.text.sp_show_base_surface[this.language];
        }
      }
      return result;
    },
    setItemStyle(index, type, surface) {
      let result = "";
      if (type === "axis") {
        if (index === 1 && surface.axis1 === "choose") {
          result = "tech-req__base_button_in-process";
        } else {
          result = "tech-req__base_button_active";
        }
      } else if (type === "surface") {
        if (index === 1 && surface.surface1 === "choose") {
          result = "tech-req__base_button_in-process";
        } else {
          result = "tech-req__base_button_active";
        }
      }
      return result;
    },
    surfaceClicked(name) {
      if (this.activeClicked) {
        if (this.clickedType === "tolerance") {
          const tolerances = this.tolerances.slice();
          const tolerance = tolerances[this.activeIndex];
          const result = [];
          if (this.activeToleranceType === "surface") {
            if (tolerance.surface1 === "choose") {
              tolerance.surface1 = name;
              result.push({
                surfaces: [name],
                color: this.clickColor,
              });
            }
          } else if (this.activeToleranceType === "axis") {
            if (tolerance.axis1 === "choose") {
              tolerance.axis1 = name;
              result.push({
                surfaces: [name],
                color: this.clickColor,
              });
            }
          }
          this.$store.state.modelV2.decorateSurfaces(result);
          this.clickedType = null;
          tolerance.status = null;
          this.activeIndex = null;
          this.activeToleranceType = null;
          this.activeClicked = false;
          this.tolerances = tolerances;
          this.$store.commit("modelV2/setShowModelFrame", false);
        }
      }
    },
    colorizeItem(index) {
      const result = [];
      const item = this.tolerances[index];
      const surfaces = [];
      if (item.surface1 && item.surface1 !== "choose") {
        surfaces.push(item.surface1);
      }
      if (item.axis1 && item.axis1 !== "choose") {
        surfaces.push(item.axis1);
      }
      result.push({
        surfaces: surfaces,
        color: this.clickColor,
      });
      this.$store.state.modelV2.decorateSurfaces(result);
    },
    setShowFilters() {
      this.showFilters = !this.showFilters;
    },
    addTolerance(type) {
      for (const item of this.tolerances) {
        item.edit = false;
      }
      this.tolerances.unshift({
        id: this.tolerances.length,
        type: type,
        value: null,
        surface1: null,
        axis1: null,
        status: null,
        count: 0,
        edit: true,
      });
      this.$store.state.modelV2.clearSurfaces();
      this.checkFilters();
      this.saveData();
    },
    saveValue(index, value) {
      this.tolerances[index].value = value;
    },
    addItem(type, index) {
      const tolerance = { ...this.tolerances[index] };
      if (tolerance.status !== "disable") {
        if (type === "surface") {
          this.activeClicked = true;
          this.$store.commit("modelV2/setShowModelFrame", true);
          if (!tolerance.surface1) {
            tolerance.surface1 = "choose";
          }
        } else if (type === "axis") {
          this.activeClicked = true;
          this.$store.commit("modelV2/setShowModelFrame", true);
          if (!tolerance.axis1) {
            tolerance.axis1 = "choose";
          }
        }
        const tolerances = this.tolerances.slice();
        tolerances[index] = tolerance;
        this.tolerances = tolerances;
      }
      tolerance.count += 1;
      tolerance.status = "choose";
      this.clickedType = "tolerance";
      this.activeIndex = index;
      this.activeToleranceType = type;
    },
    setHoveredItem(val) {
      this.hoveredItem = val;
    },
    deleteItem(index, type) {
      const tolerances = this.tolerances.slice();
      tolerances[index][type] = null;
      tolerances[index].count -= 1;
      tolerances[index].status = null;
      this.tolerances = tolerances;
      this.colorizeItem(index);
    },
    saveOrEdit(index) {
      if (this.tolerances[index].edit) {
        const tolerances = this.tolerances.slice();
        tolerances[index].edit = false;
        this.tolerances = tolerances;
        this.$store.state.modelV2.clearSurfaces();
        this.saveData();
      } else {
        const tolerances = this.tolerances.slice();
        tolerances[index].edit = true;
        this.tolerances = tolerances;
        this.colorizeItem(index);
      }
    },
    saveData() {
      const tolerances = this.tolerances;
      const accuracyShape = {
        tolerances,
      };
      query(
        "post",
        "changeTechDataByPartOrder",
        {
          hash: this.partData.hash,
          time_stamp: this.partData.time_stamp,
          file_name: this.partData.file_name,
          uuid: this.$route.params.uuid,
          form_accuracy: accuracyShape,
        },
        {
          token: localStorage.getItem("token"),
        },
      ).then(() => {
        // this.getHardness();
      });
    },
    getShapeAccuracy() {
      return new Promise((resolve) => {
        query(
          "post",
          "getTechDataByPartOrder",
          {
            form_accuracy: true,
            uuid: this.$route.params.uuid,
          },
          {
            token: localStorage.getItem("token"),
          },
        ).then((result) => {
          this.tolerances = result.form_accuracy.tolerances ? result.form_accuracy.tolerances : [];
          resolve();
        });
      });
    },
    deleteTolerance(index) {
      const tolerances = this.tolerances.slice();
      tolerances.splice(index, 1);
      this.tolerances = tolerances;
      this.saveData();
      this.checkFilters();
    },
    showSurface(index, type) {
      const result = [];
      const item = this.tolerances[index];
      const surfaces = [];
      const activeSurfaces = [];
      if (item.surface1 && item.surface1 !== "choose") {
        if (type === "surface1") {
          activeSurfaces.push(item.surface1);
        } else {
          surfaces.push(item.surface1);
        }
      }
      if (item.axis1 && item.axis1 !== "choose") {
        if (type === "axis1") {
          activeSurfaces.push(item.axis1);
        } else {
          surfaces.push(item.axis1);
        }
      }
      result.push({
        surfaces: surfaces,
        color: this.clickColor,
      });
      result.push({
        surfaces: activeSurfaces,
        color: this.activeColor,
      });
      this.$store.state.modelV2.decorateSurfaces(result);
    },
  },
  mounted() {
    this.showEdit = this.edit;
    this.getShapeAccuracy();
    this.paramsLang = this.language;
    this.checkFilters();
    this.$store.commit("modelV2/setSurfaceClick", this.surfaceClicked);
  },
  components: {
    AccuracyType,
    Button,
    AddLogo,
    Input,
    CloseIcon,
    TrashIcon,
  },
};
</script>
